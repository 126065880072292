import React, { useEffect, useState } from "react";
import DocForm from "../modules/DocForm";
import { AppHeaderComponent, LoadingComponent } from "@unity/components";
import { CanCreate } from "../services/Permissions";
import ModuleFunctions from "../common/ModuleFunctions";
import ModuleButton from "../common/ModuleButton";
import { Link } from "react-router-dom";

export default function DocumentView(props) {
  const [document, setDocument] = useState(false);
  const [sections, setSections] = useState([]);
  const [types, setTypes] = useState(false);

  const create = CanCreate("doc-creator");
  
  // Use these flags to control how the document creator creates and displays documents.
  // NB: The index is always used in the background to keep order of the sections.
  //     If the index is used you can also then decide wether subdirectories are used, i.e. 1 can have 1.1, 1.2 etc...
  //     If useIndex is false then useSubdirs is NOT checked and is classed as false regardless of setting.
  const useIndex = true;// controls if the index is displayed on the page!
  const useSubdirs = true;// controls if subdirectory items are used in the index!

  const docChange = (data) => {
    setDocument(data);
  };

  const secsChange = (data) => {
    setSections(data);
  };

  const typesChange = (data) => {
    setTypes(data);
  };

  useEffect(() => {
    ModuleFunctions.getTypes({ change: typesChange });
    ModuleFunctions.getDocument({
      id: props.route.match.params.id,
      secsChange: secsChange,
      docChange: docChange
    });
  }, []);

  if (document) {
    return (
      <>
        {create ? (
          <AppHeaderComponent
            context={props.context}
            addNew={() => (
              <Link to={"/doc-creator/create"}>
                <ModuleButton
                  text="Add New"
                  btnIcon="add"
                  stylename="primary"
                  style={{
                    background: props.context.theme.top_menu.button_background,
                    color: props.context.theme.top_menu.button_font,
                  }}
                />
              </Link>
            )}
            theme={props.context.theme}
            name="doc-creator"
            subpage="view"
          />
        ) : (
          <AppHeaderComponent
            context={context}
            theme={context.theme}
            name="doc-creator"
            subpage="view"
          />
        )}

        <div style={{ paddingTop: 1 }}>
          <DocForm
            useIndex={useIndex}
            useSubdirs={useSubdirs}
            context={props.context}
            document={document}
            sections={sections}
            types={types}
          />
        </div>
      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
