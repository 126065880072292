import React, { useState } from "react";
import Box from '@mui/material/Box';
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "../common/ModuleFunctions";

/**
 * 
 * @param boolean   edit
 * @param boolean   useIndex
 * @param boolean   useSubdirs
 * @param object    meta
 * @param object    data
 * @param object    context
 * @returns a component that contains a section of the documents data with it's optional index descriptor and associated action buttons.
 */

export default function DocSection(props) {
    const setWidth = (indent) => {
        return ((indent + 1) * 2);// plan is to set 2em's for each level of indent!
    }

    return (
        <div style={{ width: '100%' }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'baseline' }}>

                {props.useIndex &&
                    <Box
                        sx={{
                            pt: 5,
                            width: `${setWidth(props.meta.indent)}em`
                        }}
                    >
                        <p><b>{props.meta.index}</b></p>
                    </Box>
                }

                <Box sx={{ flexGrow: 1, p: '5px', borderTop: '1px solid', borderTopColor: 'lightgray' }}>
                    <div dangerouslySetInnerHTML= {{ __html: props.data.contents }} />

                    {props.edit && 
                        <div style={{ marginTop: "5px" }}>
                            {(props.useIndex && !props.meta.children) &&
                                <ModuleButton
                                    btnIcon="delete"
                                    style={{
                                        background: "red",
                                        color: "white",
                                        float: "right",
                                        marginLeft: 5
                                    }}
                                    tip="Delete"
                                    onClick={() => ModuleFunctions.handleDeleteSection({
                                        id: props.data.id || null,
                                        position: props.position,
                                        document: props.document,
                                        menu: props.menu,
                                        menuChange: props.menuChange,
                                        sectionChange: props.sectionChange,
                                        triggerChange: props.triggerChange
                                    })}
                                />
                            }
    
                            {(props.useIndex && props.useSubdirs) &&
                                <ModuleButton
                                    btnIcon="subdirectory_arrow_right"
                                    style={{
                                        background: props.context.theme.sidebar.background,
                                        color: "white",
                                        float: "right",
                                        marginLeft: 5
                                    }}
                                    tip="Add sub-section"
                                    onClick={() => ModuleFunctions.handleInsertSection({
                                        menu: props.menu,
                                        meta: props.meta,
                                        position: props.position,
                                        subsection: true,
                                        menuChange: props.menuChange,
                                        sectionChange: props.sectionChange
                                    })}
                                />
                            }
    
                            {props.useIndex &&
                                <ModuleButton
                                    btnIcon="arrow_downward"
                                    style={{
                                        background: props.context.theme.sidebar.background,
                                        color: "white",
                                        float: "right",
                                        marginLeft: 5
                                    }}
                                    tip="Add section"
                                    onClick={() => ModuleFunctions.handleInsertSection({
                                        menu: props.menu,
                                        meta: props.meta,
                                        position: props.position,
                                        subsection: false,
                                        menuChange: props.menuChange,
                                        sectionChange: props.sectionChange
                                    })}
                                />
                            }
    
                            <ModuleButton
                                btnIcon="edit"
                                style={{
                                    background: "orange",
                                    color: "white",
                                    float: "right",
                                    marginLeft: 5
                                }}
                                tip="Edit"
                                onClick={() => props.sectionChange({ type: 'section', position: props.position, obj: props.data })}
                            />
                        </div>
                    }
                </Box>
            </Box>
        </div>
    );
}
