import React, { useEffect, useState } from "react";
import DocDetails from "../modules/DocDetails";
import DocSection from "../modules/DocSection";
import Box from '@mui/material/Box';
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "../common/ModuleFunctions";

/**
 * 
 * @param boolean     edit OPTIONAL
 * @param boolean     useIndex OPTIONAL
 * @param boolean     useSubdirs OPTIONAL
 * @param object      context
 * @param object      document
 * @param object      published not required if edit = false
 * @param array       sections
 * @param array       types
 * @param object      errors OPTIONAL
 * @param array       bus not required if edit = false
 * @param array       cats not required if edit = false
 * @function docChange not required if edit = false
 * @function menuChange not required if edit = false
 * @function catsChange not required if edit = false
 * @function sectionChange not required if edit = false
 * @function triggerChange not required if edit = false
 * @returns the component containing the components that make up the document in the order set by the documents menu object.
 */

export default function DocForm(props) {
  const getSection = (id) => {
    return props.sections.find(element => {
      return element.id === id;
    })
  };

  const documentBody = (
    Array.isArray(props.document.menu) ? (
      props.document.menu.map((element, index, arr) => {
        return <DocSection
                  edit={props.edit || false}
                  useIndex={props.useIndex || false}
                  useSubdirs={props.useSubdirs || false}
                  meta={element}
                  data={element.section ? getSection(element.section) : { contents: "" }}
                  context={props.context}
                  document={props.document.id}
                  menu={arr}
                  position={index}// needed when updating the menu
                  menuChange={props.menuChange}
                  sectionChange={props.sectionChange}
                  triggerChange={props.triggerChange}
               />
      })
    ) : (<></>)
  );

  return (
    <>
      <DocDetails
        edit={props.edit || false}
        document={props.document}
        published={props.published}
        types={props.types}
        bus={props.bus}
        cats={props.cats}
        errors={props.errors || false}
        docChange={props.docChange || false}
        catsChange={props.catsChange || false}
      />

      <div className="block">
        <div className="container-fluid">
          <div style={{ width: '100%' }}>
              <Box sx={{ display: "flex", flexDirection: "row-reverse", alignItems: 'baseline' }}>
                {props.edit && 
                  <div>
                    <ModuleButton
                      btnIcon="arrow_downward"
                      style={{
                        background: props.context.theme.sidebar.background,
                        color: "white",
                        float: "right",
                        marginLeft: 5
                      }}
                      tip="Add section"
                      onClick={() => ModuleFunctions.handleInsertSection({
                        menu: props.document.menu,
                        meta: false,
                        position: null,
                        subsection: false,
                        menuChange: props.menuChange,
                        sectionChange: props.sectionChange
                      })}
                    />
                  </div>
                }
              </Box>
          </div>

          <div>{documentBody}</div>
        </div>
      </div>
    </>
  );
}
