import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import { CanCreate, CanRead, CanReadAll, CanUpdate, CanModuleAdmin } from "../services/Permissions";
import ModuleTabs from "../common/ModuleTabs";
import ModuleButton from "../common/ModuleButton";
import WorkingIndex from "../modules/WorkingIndex";
import PublishedIndex from "../modules/PublishedIndex";
import RetiredIndex from "../modules/RetiredIndex";
import ActionIndex from "../modules/ActionIndex";
import Settings from "../modules/Settings";

export default function Documents({ route, context }) {
  const [value, setValue] = useState(0);

  const create = CanCreate("doc-creator");
  const read = CanRead("doc-creator");
  const edit = CanUpdate("doc-creator");
  const admin = CanModuleAdmin("doc-creator");
  const readAll = CanReadAll("doc-creator");

  const tabs = [
    { icon: "directions_walk", label: "working" },
    { icon: "local_library", label: "published" },
    { icon: "airline_seat_flat", label: "retired" }
  ];

  if (admin) {
    tabs.push(
      { icon: "update", label: "action list" },
      { icon: "settings", label: "module settings" }
    )};

  const handleChange = async (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {}, []);


  return (
    <>
      {create ? (
        <AppHeaderComponent
          addNew={() => <Link to={"/doc-creator/create"}>
            <ModuleButton
              text="Add New"
              btnIcon="add"
              stylename="primary"
              style={
                {
                  background: context.theme.top_menu.button_background,
                  color: context.theme.top_menu.button_font,
                }
              }
            />
          </Link>}
          context={context}
          theme={context.theme}
          name={"doc-creator"}
        />
      ) : (
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name={"doc-creator"}
        />
      )}
      
      <ModuleTabs tabs={tabs} value={value} setValue={setValue} handleChange={handleChange} />
      <div style={{ paddingTop: 86 }}>
        {value === 0 && 
          <WorkingIndex
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            readAll={readAll}
          />}
        {value === 1 && 
          <PublishedIndex
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            readAll={readAll}
          />}
        {value === 2 && 
          <RetiredIndex
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            readAll={readAll}
          />}
        {value === 3 && 
          <ActionIndex
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            readAll={readAll}
          />}
        {value === 4 &&
          <Settings context={context} />}
      </div>
    </>
  );
}
