import React, { useState } from "react";
import { FormControl, FormHelperText, TextField, InputLabel, Select, MenuItem } from "@mui/material";
import ModuleFunctions from "../common/ModuleFunctions";

/**
 * 
 * @param boolean   edit
 * @param object    document
 * @param object    published
 * @param array     types
 * @param array     bus not required if edit = false
 * @param array     cats not required if edit = false
 * @param object    errors
 * @function docChange not required if edit = false
 * @function catsChange not required if edit = false
 * @returns a component containing the documents overall details and states for display to the user.
 */

export default function DocDetails(props) {

  return (
    <>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h4>Document</h4>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group-sm col-lg-10">
              <TextField
                label="Title"
                name="title"
                type="text"
                value={props.document.title || ""}
                error={props.errors && props.errors.title || false}
                helperText={props.errors && props.errors.title || ""}
                onChange={(e) => !props.edit || props.published || ModuleFunctions.handleDocChange({ name: e.target.name, value: e.target.value, docChange: props.docChange })}
                InputProps={{
                  readOnly: (props.edit || props.published) ? false : true
                }}
                size="small"
                fullWidth={true}
              ></TextField>
            </div>
            <div className="form-group-sm col-lg-2">
              <FormControl error={props.errors && props.errors.type_id || false} fullWidth>
              <InputLabel>Document Type</InputLabel>
              <Select
                label="Document Type"
                name="type_id"
                value={props.document.type_id || ""}
                onChange={(e) => !props.edit || props.published || ModuleFunctions.handleDocChange({ name: e.target.name, value: e.target.value, docChange: props.docChange })}
                inputProps={{
                    readOnly: (props.edit || props.published) ? false : true,
                }}
                size="small"
              >
                {props.types && props.types.map((item, key) => {
                    return <MenuItem key={key} value={item.id}>{item.value}</MenuItem>
                })}
              </Select>
              <FormHelperText>{props.errors && props.errors.type_id || ""}</FormHelperText>
              </FormControl>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group-sm col-lg-4">
              <TextField
                label="Author"
                name="author_name"
                type="text"
                value={props.document.author_name || ""}
                disabled={true}
                size="small"
                fullWidth={true}     
              ></TextField>
            </div>
            <div className="form-group-sm col-lg-2">
              <TextField
                label="Ref#"
                name="reference"
                type="text"
                value={props.document.reference || ""}
                disabled={true}
                size="small"
                fullWidth={true}
              ></TextField>
            </div>
            <div className="form-group-sm col-lg-2">
              <TextField
                label="Status"
                name="status"
                type="text"
                value={props.document.status || ""}
                disabled={true}
                size="small"
                fullWidth={true}
              ></TextField>
            </div>
            <div className="form-group-sm col-lg-2">
              <TextField
                label="Version"
                name="version"
                type="text"
                value={props.document.version || ""}
                disabled={true}
                size="small"
                fullWidth={true}     
              ></TextField>
            </div>
            <div className="form-group-sm col-lg-2">
              <TextField
                label="Creation date"
                name="created_date"
                value={props.document.created_date}
                disabled={true}
                size="small"
                fullWidth={true}
              ></TextField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group-sm col-lg-6">
              {!props.edit ? (
                <TextField
                  label="Business Unit"
                  type="text"
                  value={props.document.sbu_name || ""}
                  InputProps={{
                    readOnly: (props.edit || props.published) ? false : true
                  }}
                  size="small"
                  fullWidth={true}
                ></TextField>
              ) : (
                <FormControl fullWidth>
                <InputLabel>Business Unit</InputLabel>
                <Select
                  label="Select to assign to a Business Unit"
                  name="sbu_id"
                  value={props.document.sbu_id || ""}
                  error={props.errors && props.errors.sbu_id || false}
                  helperText={props.errors && props.errors.sbu_id || ""}
                  onChange={(evt, menuItem) => !props.edit || props.published || ModuleFunctions.handleAssign({ event: evt, element: menuItem, docChange: props.docChange, catsChange: props.catsChange })}
                  inputProps={{
                      readOnly: (props.edit || props.published) ? false : true,
                  }}
                  size="small"
                >
                  {props.bus && props.bus.map((item, key) => {
                      return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                  })}
                </Select>
                </FormControl>
              )}
            </div>
            <div className="form-group-sm col-lg-6">
            {!props.edit ? (
                <TextField
                  label="Product Category"
                  type="text"
                  value={props.document.prod_cat_name || ""}
                  InputProps={{
                    readOnly: (props.edit || props.published) ? false : true
                  }}
                  size="small"
                  fullWidth={true}
                ></TextField>
              ) : (
                <FormControl fullWidth>
                <InputLabel>Product Category</InputLabel>
                <Select
                  label="Select to assign to a Product Category"
                  name="prod_cat_id"
                  value={props.document.prod_cat_id || ""}
                  error={props.errors && props.errors.prod_cat_id || false}
                  helperText={props.errors && props.errors.prod_cat_id || ""}
                  onChange={(evt, menuItem) => !props.edit || props.published || ModuleFunctions.handleAssign({ event: evt, element: menuItem, docChange: props.docChange, catsChange: props.catsChange })}
                  inputProps={{
                      readOnly: (props.edit || props.published) ? false : true,
                  }}
                  size="small"
                >
                  {props.cats && props.cats.map((item, key) => {
                      return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                  })}
                </Select>
                </FormControl>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group-sm col-lg-4">
              <TextField
                label="Approved by"
                name="approved_name"
                type="text"
                value={props.document.approved_name || ""}
                disabled={true}
                size="small"
                fullWidth={true}     
              />
            </div>
            <div className="form-group-sm col-lg-2">
              <TextField
                label="Approved date"
                name="approved_date"
                value={props.document.approved_date || ""}
                disabled={true}
                size="small"
                fullWidth={true}
              ></TextField>
            </div>   
            <div className="form-group-sm col-lg-4">
              <TextField
                label="Published by"
                name="published_name"
                type="text"
                value={props.document.published_name || ""}
                disabled={true}
                size="small"
                fullWidth={true}
              ></TextField>
            </div>
            <div className="form-group col-lg-2">
              <TextField
                label="Published date"
                name="published_date"
                value={props.document.published_date || ""}
                disabled={true}
                size="small"
                fullWidth={true}
              ></TextField>
            </div>             
          </div>
        </div>
      </div>
    </>
  );
}
