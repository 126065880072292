import React, { useState, useEffect } from "react";
import DataTable from "../common/DataTable";
import DocDetails from "../modules/DocDetails";
import { CanCreate, CanUpdate } from "../services/Permissions";
import { DocCreatorApi, AppHeaderComponent, LoadingComponent } from "@unity/components";
import ModuleFunctions from "../common/ModuleFunctions";
import ModuleButton from "../common/ModuleButton";
import { Link } from "react-router-dom";

export default function DocumentHistory(props) {
    const [loading, setLoading] = useState({ status: false });
    const [data, setData] = useState(false);
    const [types, setTypes] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState(false);// Flag to switch between unpaginated and paginated data!
    const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
    const [searchText, setSearchText] = useState('');
    const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!


    const create = CanCreate("doc-creator");
    const edit = CanUpdate("doc-creator");

    const typesChange = (data) => {
      setTypes(data);
    };
  
    const getLogs = async () => {
        const res = await DocCreatorApi.getVersionLogs( props.route.location.state.theme_id, props.route.location.state.version );

        if (res.success) {
          setData(res.data);
        } else {
          // the request has failed, load an empty array!
          setData([]);  
        }
    };  

    const handlePageChange = (page) => {};
    const handleSizeChange = (size) => {};

    const columns = [
        { field: "version_created", headerName: "Version", flex: 0.1 },
        { field: "created_date", headerName: "Date", flex: 0.2 },
        { field: "created_name", headerName: "Performed by", flex: 0.3 },
        { field: "event", headerName: "Event type", flex: 0.3 },
        { field: "description", headerName: "Log", flex: 0.7 }
    ];  

    const options = {
        page: 0,
        handlePageChange: handlePageChange,
        pageSize: 0,
        handleSizeChange: handleSizeChange,
        rowsPerPageOptions: [10, 25, 100],
        rowCount: 0,
        autoHeight: true,
        height: 680
    };  

    useEffect(() => {
        ModuleFunctions.getTypes({ change: typesChange });
        getLogs();
    }, []); 


    if (data) {
    return (
      <>
        <AppHeaderComponent
          context={props.context}
          addNew={() => create ? <Link to={"/doc-creator/create"}>
            <ModuleButton
              text="Add New"
              btnIcon="add"
              stylename="primary"
              style={
                {
                  background: props.context.theme.top_menu.button_background,
                  color: props.context.theme.top_menu.button_font,
                }
              }
            />
          </Link> : null}
          editItem={() => edit && (!props.route.location.state.requested || !props.route.location.state.approved) ? 
            <Link to={`/abc/edit/${props.route.match.params.id}`}>
              <ModuleButton
                text="Edit"
                btnIcon="edit"
                stylename="primary"
                style={
                  {
                    background: props.context.theme.top_menu.button_background,
                    color: props.context.theme.top_menu.button_font,
                  }
                }
              />
            </Link> : null}
          theme={props.context.theme}
          name="doc-creator"
          subpage="history"
        />

        <div style={{ paddingTop: 1 }}>
          <DocDetails
            document={props.route.location.state}
            errors={false}
            edit={false}
            published={false}
            types={types}
            docChange={false}
            catsChange={false}
          />
        </div>

        <div style={{ paddingTop: 20 }}>
          <DataTable
            columns={columns}
            data={data}
            pagination={pagination}
            options={options}
            allowDelete={false}
            handleDeleteArray={false}
            searchText={searchText}
            setSearchText={setSearchText}
            fetchTrigger={fetchTrigger}
            setFetchTrigger={setFetchTrigger}
          />
        </div>
      </>
    );
  } else {
    return <LoadingComponent />;    
  }
}
