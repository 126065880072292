import { DocCreatorApi, CodesApi } from "@unity/components";

async function getDocument(props) {
    const res = await DocCreatorApi.getDocument(props.id);
    if (res.success) {
        props.secsChange(res.data.sections);
        delete res.data.sections;
        props.docChange(res.data);
        if (res.data.sbu_id && props.catsChange) {
            getProductCats({ change: props.catsChange, id: res.data.sbu_id });
        }
    }
};

async function getTypes(props) {
    const res = await DocCreatorApi.getTypes();
    if (res.success) {
        props.change(res.data);
    }
}

async function getBusinesses(props) {
    const res = await CodesApi.getBusinessUnits();
    if (res.success) {
        props.change(res.data);
    }
}

async function getProductCats(props) {
    const res = await CodesApi.subOptionsIndexForOption(props.id);
    if (res.success) {
        props.change(res.data);
    }
}

async function handleAssign(props) {
    const newData = {};
    newData[props.event.target.name] = props.event.target.value;
    if (props.event.target.name === "sbu_id") {
        getProductCats({ change: props.catsChange, id: props.event.target.value });
        newData["sbu_name"] = props.element.props.children;
    } else {
        newData["prod_cat_name"] = props.element.props.children;
    }
    props.docChange(newData);
}

function handleDocChange(props) {
    const newData = {};
    newData[props.name] = props.value;
    props.docChange(newData);
};

function handleLogChange(props) {
    const newData = {};
    newData[props.name] = props.value;
    props.logChange(newData);
};

function handleCopy(props) {
    const newData = {};
    newData["copied"] = props.event.target.value;
    newData["menu"] = props.element.props.menu;
    props.docChange(newData);
};

function testValid(props) {
    let issues = 0;
    const newErrs = {};
    if(!props.document || !props.document.title) {
        newErrs["title"] = "You must give the document a title!";
        issues += 1;
    }
    if(!props.document || !props.document.type_id) {
        newErrs["type_id"] = "You must select the type!";
        issues += 1;
    }
    if(!props.log || !props.log.description) {
        newErrs["description"] = "You must give a ddocument description for the log entry!";
        issues += 1;
    }
    return (issues > 0) ? newErrs : false;
};

function testReason(props) {
    let issues = 0;
    const newErrs = {};
    if(!props.reason) {
        newErrs["reason"] = "You must give a approval rejection reason!";
        issues += 1;
    }
    return (issues > 0) ? newErrs : false;
};

/**
 * This function creates and sends a log for the document action.
 * 
 * @param {*}       props       has the elements: 
 * @param {object}  types       array of document types
 * @param {number}  type_id     id of the selected document type
 * @param {object}  document    that the log relates to
 * @param {object}  log         log
 * @param {string}  event       event type [e.g. created, section created, updated, section deleted, etc]
 */
async function handleLogSave(props) {
    const fullLog = {};
    const type = props.types.find(e => e.id === props.type_id);
    fullLog["theme_id"] = props.document.theme_id || null;
    fullLog["version"] = props.document.version || null;
    fullLog["event"] = type.value + ' ' + props.event;
    fullLog["description"] = props.log.description || props.log;
    // the only user input field is checked by the testValid function, the rest is
    // added by this function when theme is created so we assume saveLog will be successful.
    await DocCreatorApi.saveLog(fullLog);
};

/**
 * This function will delete a section at the specified point in the menu.
 * 
 * @param   {*}         props       has the elements:
 * @param   {number}    id          the current section id
 * @param   {number}    position    the rendered position of the section in the menu
 * @param   {object}    menu        the menu object of the document
 * @function    menuChange           to update the document state
 * @function    sectionChange           to update the document state
 * @function    triggerChange        to update the document state
 */
async function handleDeleteSection(props) {
    let newMenu = [...props.menu];

    if((props.position > 0) && (newMenu[props.position - 1]['indent'] < newMenu[props.position]['indent'])
        && (newMenu[props.position + 1]['indent'] < newMenu[props.position]['indent'])) {
            newMenu[props.position - 1]['children'] = false;
    }

    newMenu.splice(props.position,1);
    const mappedMenu = remapMenu(newMenu);

    if(props.id !== null) {
        const res = await DocCreatorApi.unlinkSection(props.id, { document_id: props.document, menu: mappedMenu });
        if(res.success) {
            props.menuChange({ menu: mappedMenu });
            props.triggerChange;
        }
    } else {
        props.menuChange({ menu: mappedMenu });
        props.sectionChange(false);
    }
};

/**
 * This function will add a section at the specified point in the menu.
 * 
 * @param   {*}         props       has the elements:
 * @param   {object}    menu        the menu object of the document
 * @param   {object}    meta        the current section meta data OR false if the start of the document
 * @param   {number}    position    the rendered position of the section in the menu, should be set as null if not called from a section and will be assumed to be first section!
 * @param   {boolean}   subsection  flag if the added section is a subsection to the calling section
 * @function    menuChange           to update the document state
 * @function    sectionChange           to update the document state
 */
async function handleInsertSection(props) {
    let newMenu = Array.isArray(props.menu) ? [...props.menu] : [];
    let newIndent = 0;
    let insertPos = 0;

    if(props.position !== null) {
        insertPos = (props.position + 1);
        newIndent = props.subsection ? (props.meta.indent + 1) : props.meta.indent;
    }

    if(props.subsection) {
        newMenu[props.position]['children'] = true;
    }

    newMenu.splice(insertPos,0,{ index: "1.", indent: newIndent, section: null, children: false })
    const mappedMenu = remapMenu(newMenu);
    props.menuChange({ menu: mappedMenu });
    props.sectionChange({ type: 'section', position:  insertPos, obj: { contents: "" } });
};

function remapMenu(menu) {
    let currentIndex = "1.";

    menu.map((element, index) => {

        if(index > 0) {
            let expIdx = currentIndex.split('.');
            expIdx.pop();// just gets rid of the empty element after the dot
            let idxLen = expIdx.length;
            let newIndex = '';

            if(element.indent > (idxLen - 1)) {
                expIdx.push('0');
            }

            // This need to account for dropping more than 1 level at once! 
            if(element.indent < (idxLen - 1)) {
                let diff = (idxLen - 1) - element.indent;

                for(let count = diff; count > 0; count--) {
                    expIdx.pop();
                }
            }

            expIdx[element.indent] = (parseInt(expIdx[element.indent]) + 1);

            expIdx.map((element) => {
                newIndex += (element + '.');
            });
            
            element.index = newIndex;
            currentIndex = newIndex;
        } else {
            element.index = currentIndex;
        }
    });

    return menu;
};

export default {
    getDocument,
    getTypes,
    getBusinesses,
    getProductCats,
    handleAssign,
    handleDocChange,
    handleLogChange,
    handleCopy,
    testValid,
    testReason,
    handleLogSave,
    handleDeleteSection,
    handleInsertSection
};