import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../common/DataTable";
import { DocCreatorApi, DocumentVaultAPI, ApiLoaderComponent, LoadingComponent } from "@unity/components";
import ModuleButton from "../common/ModuleButton";
import InputCapture from "../common/InputCapture";
import ModuleFunctions from "../common/ModuleFunctions";

export default function PublishedIndex({ context, create, read, edit, admin, readAll }) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  const [types, setTypes] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState(true);// Flag to switch between unpaginated and paginated data!
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [searchText, setSearchText] = useState('');
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!

  const history = useHistory();

  const typesChange = (data) => {
    setTypes(data);
  };

  const dialogChange = (data) => {
    setOpen(true);
    setDialogData(prevState => ({
      ...prevState,
      ...data
    }));
  };

  const getDocs = async () => {
    const res = await DocCreatorApi.getPublishedDocuments({ read_all: readAll, pagination: pagination, page: page + 1, per_page: pageSize, search_term: searchText });

    if(res.success) {
      setData(res.data);
      if(pagination) {
        setRowCount(res.meta.total);
      }
    } else {
      // the request has failed, load an empty array!
      setData([]);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);

    if ( pagination ) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if(pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleLinkClick = (event, params, path) => {
      event.preventDefault();
      history.push({
        pathname: `/doc-creator/${path}/${params.id}`,
        state: { ...params }
      });
  };

  const handleDownloadRequest = async (id) => {
    const res = await DocumentVaultAPI.generateTempLink(id);
    var win = window.open(res.data, "_blank");
    win.focus();
  };

  const handleNewVersion = async (document) => {
    setLoading({ status: true, data: 'Creating new version of the document, please wait...' });

    const res = await DocCreatorApi.saveDocument(document);

    if (res.success) {
      let main = Math.floor(res.data.version);

      await ModuleFunctions.handleLogSave({
        type_id: res.data.type_id,
        types: types,
        log: `New version created from published version ${main}.0`,
        document: res.data, event: "updated"
      });

      setTimeout(() => {
        history.push(`/doc-creator/edit/${res.data.id}`);
        setLoading({ status: false });
      });
    } else {
      setLoading({ status: true, data: res.message });
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
    }

    setFetchTrigger(fetchTrigger + 1);
  };

  const handleRetire = async () => {
    setLoading({ status: true, data: 'Retiring document, please wait...' });

    const testResults = ModuleFunctions.testReason({ reason: dialogData.reason });

    if(testResults) {
      setErrors(testResults);
      setLoading({ status: false });
    } else {
      const res = await DocCreatorApi.documentRetirement(dialogData.id, { reason: dialogData.reason });

      if (res.success) {
        await ModuleFunctions.handleLogSave({
          type_id: res.data.type_id,
          types: types,
          log: "Document has been retired.",
          document: res.data, event: "retired"
        });

        setLoading({ status: false });
        setOpen(false);
        setDialogData(false);
        setErrors(false);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      }

      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const columns = [
    { field: "reference", headerName: "Ref#", flex: 0.3 },
    { field: "title", headerName: "Document Name", flex: 1 },
    { field: "version", headerName: "Version", flex: 0.2 },
    { field: "bu_name", headerName: "Business Unit", flex: 0.5 },
    {
      field: "",
      headerName: "Action",
      minWidth: 400,
      renderCell: (cellValues) => {
        return (
          <div>
            <ModuleButton
              btnIcon="visibility"
              style={{
                background: context.theme.sidebar.background,
                color: "white"
              }}
              onClick={(event) => handleLinkClick(event, cellValues.row, "view")}
              tip="View"
            />
            <ModuleButton
              btnIcon="chrome_reader_mode"
              style={{
                background: context.theme.sidebar.background,
                color: "white",
                marginLeft: 10
              }}
              onClick={(event) => handleLinkClick(event, cellValues.row, "history")}
              tip="History"
            />
            <ModuleButton
              btnIcon="cloud_download"
              style={{
                background: context.theme.sidebar.background,
                color: "white",
                marginLeft: 10
              }}
              onClick={() => handleDownloadRequest(cellValues.row.pdf_id)}
              tip="Download"
            />
            {edit && (cellValues.row.draft_version_id === null) &&
              <ModuleButton
                btnIcon="edit"
                style={{
                  background: "orange",
                  color: "white",
                  marginLeft: 10
                }}
                onClick={() => handleNewVersion(cellValues.row)}
                tip="Update"
              />
            }
            {admin &&
              <ModuleButton
                btnIcon="airline_seat_flat"
                style={{
                  background: "red",
                  color: "white",
                  marginLeft: 10
                }}
                onClick={() => dialogChange({ id: cellValues.row.id })}
                tip="Retire"
              />
            }
          </div>
        );
      },
    }
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 680
  };
  
  useEffect(() => {
    ModuleFunctions.getTypes({ change: typesChange });
    getDocs();
  }, [fetchTrigger]);


  if (read && data) {
    return (
      <>
        <DataTable
          columns={columns}
          data={data}
          pagination={pagination}
          options={options}
          allowDelete={false}
          handleDeleteArray={false}
          searchText={searchText}
          setSearchText={setSearchText}
          fetchTrigger={fetchTrigger}
          setFetchTrigger={setFetchTrigger}
        />

        <InputCapture
          title="Retirement Reason"
          info="You are about to retire this document! It will no longer be available for referencing by new products or contracts. Are you sure you want to continue?"
          open={open}
          setOpen={setOpen}
          errors={errors}
          setErrors={setErrors}
          dialogData={dialogData}
          setDialog={setDialogData}
          dialogChange={dialogChange}
          handleSubmit={handleRetire}
        />

        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
