import React from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import Documents from "./pages";
import DocumentCreate from "./pages/create";                      
import DocumentView from "./pages/view";
import DocumentEdit from "./pages/edit";
import DocumentHistory from "./pages/history";

export default function Root(props) {
  let context = { ...props };
  let history = useHistory();

  return (
    <AuthContext.Provider value={{ ...props }}>
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                    {props.auth.access["doc-creator"]["A"] &&
                      props.auth.access["doc-creator"]["R"] && (
                        <Route
                          path="/doc-creator/index"
                          exact
                          component={(route) => (
                            <Documents
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access["doc-creator"]["A"] &&
                      props.auth.access["doc-creator"]["U"] && (
                        <Route
                          path="/doc-creator/edit/:id"
                          exact
                          component={(route) => (
                            <DocumentEdit
                              route={route}
                              context={context}
                              history={history}
                            />
                          )}
                        />
                      )}

                    {props.auth.access["doc-creator"]["A"] &&
                      props.auth.access["doc-creator"]["R"] && (
                        <Route
                          path="/doc-creator/view/:id"
                          exact
                          component={(route) => (
                            <DocumentView
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}

                      {props.auth.access["doc-creator"]["A"] &&
                        props.auth.access["doc-creator"]["R"] && (
                          <Route
                            path="/doc-creator/history/:id"
                            exact
                            component={(route) => (
                              <DocumentHistory
                                route={route}
                                context={context}
                                history={history}
                              />
                            )}
                          />
                        )}

                    {props.auth.access["doc-creator"]["A"] &&
                      props.auth.access["doc-creator"]["C"] && (
                        <Route
                          path="/doc-creator/create"
                          exact
                          component={(route) => (
                            <DocumentCreate
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
