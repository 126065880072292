import React, { useEffect, useState } from "react";
import DocForm from "../modules/DocForm";
import DocEditForm from "../modules/DocEditForm";
import InputCapture from "../common/InputCapture";
import MediaLibrary from "../common/MediaLibrary";
import { DocCreatorApi, DocumentVaultAPI, AppHeaderComponent, ApiLoaderComponent, LoadingComponent } from "@unity/components";
import { CanUpdate } from "../services/Permissions";
import ModuleFunctions from "../common/ModuleFunctions";
import { useHistory } from "react-router";

export default function DocumentEdit(props) {
  const [document, setDocument] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [published, setPublished] = useState(false);
  const [sections, setSections] = useState([]);
  const [errors, setErrors] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  const [types, setTypes] = useState(false);
  const [bus, setBus] = useState(false);
  const [cats, setCats] = useState(false);
  const [media, setMedia] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [loading, setLoading] = useState({ status: false });
  const [section, setSection] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!

  const edit = CanUpdate("doc-creator");
  
  // Use these flags to control how the document creator creates and displays documents.
  // NB: The index is always used in the background to keep order of the sections.
  //     If the index is used you can also then decide wether subdirectories are used, i.e. 1 can have 1.1, 1.2 etc...
  //     If useIndex is false then useSubdirs is NOT checked and is classed as false regardless of setting.
  const useIndex = true;// controls if the index is displayed on the page!
  const useSubdirs = true;// controls if subdirectory items are used in the index!

  let history = useHistory();

  const dialogChange = (data) => {
    setOpen(true);
    setDialogData(prevState => ({
      ...prevState,
      ...data
    }));
  };

  const docChange = (data) => {
    setDocument(prevState => ({
      ...prevState,
      ...data
    }));
    // do not set updated on initial state set!
    if(document !== false) {
      setUpdated(true);
    }
    if(data.version >= 1.0) {
      setPublished(true);
    }
  };

  const menuChange = (data) => {
    setDocument(prevState => ({
      ...prevState,
      ...data
    }));
  };

  const secsChange = (data) => {
    setSections(data);
  };

  const typesChange = (data) => {
    setTypes(data);
  };

  const busChange = (data) => {
    setBus(data);
  };

  const catsChange = (data) => {
    setCats(data);
  };

  // this is for the DocEditForm
  const sectionChange = (type) => {
    setSection(type);
  };

  const mediaChange = async () => {
    setMedia(true);
    const res = await DocumentVaultAPI.documentsIndex(1);
    setMediaData(res.data);
  }

  const mediaClose = () => {
    setMedia(false);
  }

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };

  const loadingChange = (data) => {
    setLoading(data);
  };

  const handleSubmit = async () => {
    setLoading({ status: true, data: "Saving your record, Please Wait...." });

    const res = await DocCreatorApi.updateDocument(props.route.match.params.id, document);

    if(res.success) {
      setLoading({ status: false });
    } else {
      if(res.errors) {
        setErrors(res.errors);
        setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          history.push("/doc-creator/index");
          setLoading({ status: false });
        }, 3000);
      }
    }
  };

  const handleSectionCapture = async (newSection) => {
    setOpen(true);
    setDialogData(newSection);
  };

  const handleCaptureSubmit = async () => {
    const testResults = ModuleFunctions.testReason({ reason: dialogData.reason });

    if(testResults) {
      setErrors(testResults);
      setLoading({ status: false });
    } else {
      loadingChange({ status: true, data: "Saving your section, Please Wait...." });

      // only set the section id of the menu item as null now otherwise it causes an issue if cancelled!
      let newSection = {...dialogData};
      newSection.menu[newSection.position]['section'] = null;
      let replacedIdx = newSection.menu[newSection.position]['index'];
      let saveType = newSection.update ? 'updated' : 'created';
      delete newSection.position;
      delete newSection.update;

      const res = await DocCreatorApi.saveSection(newSection);

      if(res.success) {
        await ModuleFunctions.handleLogSave({
          type_id: document.type_id,
          types: types,
          log: dialogData.reason,
          document: document,
          event: `section ${replacedIdx} ${saveType}`
        });

        setTimeout(() => {
          sectionChange(false);
          setOpen(false);
          setDialogData(false);
          setErrors(false);
          history.push(`/doc-creator/edit/${document.id}`);
          loadingChange({ status: false });
        });
      } else {
        loadingChange({ status: true, data: res.message });
        setTimeout(() => {
          history.push(`/doc-creator/edit/${document.id}`);
          loadingChange({ status: false });
        }, 3000);
      }
    }
  };

  const handleSectionSubmit = async (update, newSection) => {
    loadingChange({ status: true, data: "Saving your section, Please Wait...." });

    const res = update ? await DocCreatorApi.updateSection(section.obj.id, newSection) : await DocCreatorApi.saveSection(newSection);

    if(res.success) {
      setTimeout(() => {
        sectionChange(false);
        setOpen(false);
        setDialogData(false);
        setErrors(false);
        history.push(`/doc-creator/edit/${document.id}`);
        loadingChange({ status: false });
      });
    } else {
      loadingChange({ status: true, data: res.message });
      setTimeout(() => {
        history.push(`/doc-creator/edit/${document.id}`);
        loadingChange({ status: false });
      }, 3000);
    }
  };


  useEffect(() => {
    ModuleFunctions.getBusinesses({ change: busChange });
    ModuleFunctions.getTypes({ change: typesChange });
    ModuleFunctions.getDocument({
      id: props.route.match.params.id,
      secsChange: secsChange,
      docChange: docChange,
      catsChange: catsChange
    });
  }, [fetchTrigger]);// re-render if the menu changes



  if (document) {
    return (
      <>
        {edit ? (
          <AppHeaderComponent
            context={props.context}
            theme={props.context.theme}
            name="doc-creator"
            subpage="edit"
            saveBtn={(!published && updated) ? handleSubmit : null}
          />
        ) : (
          <AppHeaderComponent
            context={props.context}
            theme={props.context.theme}
            name="doc-creator"
            subpage="edit"
          />
        )}

        <div style={{ paddingTop: 1 }}>
          {section ? (
            section.type === 'section' ? (
              <DocEditForm
                context={props.context}
                document={document}
                section={section}
                sectionChange={sectionChange}
                menuChange={menuChange}
                triggerChange={triggerChange}
                mediaChange={mediaChange}
                handleCapture={handleSectionCapture}
                handleSubmit={handleSectionSubmit}
              />
            ) : section.type === 'delete' ? (
              <></>
            ) : section.type === 'insert' ? (
              <></>
            ) : (
              <></>
            )
          ) : (
            <DocForm
              edit={edit}
              useIndex={useIndex}
              useSubdirs={useSubdirs}
              context={props.context}
              document={document}
              published={published}
              sections={sections}
              types={types}
              bus={bus}
              cats={cats}
              errors={errors}
              docChange={docChange}
              menuChange={menuChange}
              catsChange={catsChange}
              sectionChange={sectionChange}
              triggerChange={triggerChange}
            />
          )}
        </div>

        <InputCapture
          title="Section Update Reason"
          info="You must supply a reason for the log entry about why this section has been updated"
          open={open}
          setOpen={setOpen}
          errors={errors}
          setErrors={setErrors}
          dialogData={dialogData}
          setDialog={setDialogData}
          dialogChange={dialogChange}
          handleSubmit={handleCaptureSubmit}
        />
        
        <MediaLibrary
          open={media}
          data={mediaData}
          onClose={mediaClose}
          context={props.context}
        />
        
        <ApiLoaderComponent
          status={loading.status}
          data={loading.data}
        />
      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
