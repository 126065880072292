import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Box from '@mui/material/Box';
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "../common/ModuleFunctions";

export default function DocEditForm(props) {
  // console.log("DocEditForm: ",props);
  const editorRef = useRef(null);
  const update = props.section.obj.id ? true : false;
  let section = {
    theme_id: props.document.theme_id,
    document_id: props.document.id,
    version: props.document.version,
    contents: props.section.obj.contents,
    menu: props.document.menu
  };

  const handleSectionSave = async () => {
    section.contents = editorRef.current.getContent();

    if((!props.section.obj.id && (props.document.version > 0.1)) || (props.section.obj.id && (props.section.obj.version_created !== props.document.version))) {
      // set the section to unlink
      section.to_unlink = props.section.obj.id;
      // need this in the save function
      section.position = props.section.position;
      section.update = update;

      props.handleCapture(section);
    } else {
      props.handleSubmit(update, section);
    }
  };

  return (
    <div className="block">
      <div className="container-fluid">
        <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={props.section.obj.contents}
            init={{
                height: 500,
                menubar: true,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
        />

        <div style={{ width: '100%', marginTop: "10px" }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <ModuleButton
              text="media library"
              btnIcon="perm_media"
              style={{
                background: props.context.theme.sidebar.background,
                color: "white",
                margin: 5
              }}
              tip="Browse the Unity media library"
              onClick={() => props.mediaChange()}
            />
            <ModuleButton
              btnIcon="check"
              text="save"
              style={{
                  background: "green",
                  color: "white",
                  margin: 5
              }}
              tip="Save changes"
              onClick={() => handleSectionSave()}
            />
            <ModuleButton
              btnIcon="close"
              text="discard"
              style={{
                  background: "red",
                  color: "white",
                  margin: 5
              }}
              tip="Discard changes"
              onClick={() => !props.section.obj.id ? (
                  ModuleFunctions.handleDeleteSection({
                    id: props.section.obj.id || null,
                    position: props.section.position,
                    document: props.document.id,
                    menu: props.document.menu,
                    menuChange: props.menuChange,
                    sectionChange: props.sectionChange,
                    triggerChange: props.triggerChange
                  })
              ) : props.sectionChange(false)}
            />
          </Box>
        </div>
      </div>
    </div>
  );
}
