import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../common/DataTable";
import { DocCreatorApi, ApiLoaderComponent, LoadingComponent } from "@unity/components";
import ModuleButton from "../common/ModuleButton";

export default function RetiredIndex({ context, create, read, edit, admin, readAll }) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState(true);// Flag to switch between unpaginated and paginated data!
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [searchText, setSearchText] = useState('');
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!

  const history = useHistory();

  const getDocs = async () => {
    const res = await DocCreatorApi.getRetiredDocuments({ read_all: readAll, pagination: pagination, page: page + 1, per_page: pageSize, search_term: searchText });

    if (res.success) {
      setData(res.data);
      if (pagination) {
        setRowCount(res.meta.total);
      }
    } else {
      // the request has failed, load an empty array!
      setData([]);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);

    if ( pagination ) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if( pagination ) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleLinkClick = (event, params, path) => {
      event.preventDefault();
      history.push({
        pathname: `/doc-creator/${path}/${params.id}`,
        state: { ...params }
      });
  };

  const handleDownloadRequest = async (id) => {
    const res = await DocumentVaultAPI.generateTempLink(id);
    var win = window.open(res.data, "_blank");
    win.focus();
  };

  const columns = [
    { field: "reference", headerName: "Ref#", flex: 0.3 },
    { field: "title", headerName: "Document Name", flex: 1 },
    { field: "version", headerName: "Version", flex: 0.2 },
    { field: "bu_name", headerName: "Business Unit", flex: 0.5 },
    {
      field: "",
      headerName: "Action",
      minWidth: 400,
      renderCell: (cellValues) => {
        return (
          <div>
            <ModuleButton
              btnIcon="visibility"
              style={{
                background: context.theme.sidebar.background,
                color: "white"
              }}
              onClick={(event) => handleLinkClick(event, cellValues.row, "view")}
              tip="View"
            />
            <ModuleButton
              btnIcon="chrome_reader_mode"
              style={{
                background: context.theme.sidebar.background,
                color: "white",
                marginLeft: 10
              }}
              onClick={(event) => handleLinkClick(event, cellValues.row, "history")}
              tip="History"
            />
            <ModuleButton
              btnIcon="cloud_download"
              style={{
                background: context.theme.sidebar.background,
                color: "white",
                marginLeft: 10
              }}
              tip="Download"
              onClick={() => handleDownloadRequest(cellValues.row.pdf_id)}
            />
          </div>
        );
      },
    }
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 680
  };
  
  useEffect(() => {
    getDocs();
  }, [fetchTrigger]);


  if (read && data) {
    return (
      <>
        <DataTable
          columns={columns}
          data={data}
          pagination={pagination}
          options={options}
          allowDelete={false}
          handleDeleteArray={false}
          searchText={searchText}
          setSearchText={setSearchText}
          fetchTrigger={fetchTrigger}
          setFetchTrigger={setFetchTrigger}
        />
        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
