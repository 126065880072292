import React, { useEffect, useState } from "react";
import DocSetupForm from "../modules/DocSetupForm";
import { DocCreatorApi, AppHeaderComponent, ApiLoaderComponent, LoadingComponent } from "@unity/components";
import ModuleFunctions from "../common/ModuleFunctions";
import { useHistory } from "react-router";

export default function DocumentCreate(props) {
  const [published, setPublished] = useState(false);
  const [document, setDocument] = useState(false);
  const [log, setLog] = useState(false);
  const [errors, setErrors] = useState(false);
  const [types, setTypes] = useState(false);
  const [bus, setBus] = useState(false);
  const [cats, setCats] = useState(false);
  const [loading, setLoading] = useState({ status: false });

  let history = useHistory();

  const docChange = (data) => {
    setDocument(prevState => ({
      ...prevState,
      ...data
    }));
  };

  const logChange = (data) => {
    setLog(prevState => ({
      ...prevState,
      ...data
    }));
  };

  const typesChange = (data) => {
    setTypes(data);
  };

  const busChange = (data) => {
    setBus(data);
  };

  const catsChange = (data) => {
    setCats(data);
  };

  const getDocs = async () => {
    const res = await DocCreatorApi.getPublishedDocuments({ read_all: true, pagination: false });
    if (res.success) {
      setPublished(res.data);
    } else {
      // the request has failed, load an empty array!
      setPublished([]);
    }
  };

  const handleSubmit = async () => {
    setLoading({ status: !loading.status, data: "Saving your record, Please Wait...." });

    const testResults = ModuleFunctions.testValid({ document: document, log: log });

    if(testResults) {
      setErrors(testResults);
      setLoading({ status: false });
    } else {
      const res = await DocCreatorApi.saveDocument(document);

      if(res.success) {
        await ModuleFunctions.handleLogSave({ type_id: document.type_id, types: types, log: log, document: res.data, event: "created" });
  
        setTimeout(() => {
          history.push(`/doc-creator/edit/${res.data.id}`);
          setLoading({ status: false });
        });
      } else {
        if(res.errors) {
          setErrors(res.errors);
          setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
          setTimeout(() => {
            setLoading({ status: false });
          }, 3000);
        } else {
          setLoading({ status: true, data: res.message });
          setTimeout(() => {
            history.push("/doc-creator/index");
            setLoading({ status: false });
          }, 3000);
        }
      }
    }
  };

  useEffect(() => {
    ModuleFunctions.getBusinesses({ change: busChange });
    ModuleFunctions.getTypes({ change: typesChange });
    getDocs();
  }, []);


  if (published) {
    return (
      <>
        <AppHeaderComponent
          name={"doc-creator"}
          subpage={"create"}
          context={props.context}
          theme={props.context.theme}
          saveBtn={handleSubmit}
        />

        <div style={{ paddingTop: 1 }}>
          <DocSetupForm
            published={published}
            document={document}
            types={types}
            bus={bus}
            cats={cats}
            log={log}
            errors={errors || false}
            docChange={docChange}
            catsChange={catsChange}
            logChange={logChange}
          />
        </div>
        
        <ApiLoaderComponent
          status={loading.status}
          data={loading.data}
        />
      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
