import React, { useState } from "react";
import { Dialog, DialogTitle, Snackbar, Button, CircularProgress, IconButton, Icon } from "@mui/material";
import ModuleButton from "../common/ModuleButton";
import DataTable from "../common/DataTable";
import { DocumentVaultAPI } from "@unity/components";

export default function MediaLibary(props) {
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessge, setSnackMessage] = useState("");
  const [showUpload, setShowUpload] = useState("none");
  const [uploadData, setUploadData] = useState({});
  const [uploading, setUploading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const handleInputChange = (event) => {
    const files = event.target.files;
    let reader = new FileReader();
    let toUpload = [];

    for (let i = 0; i < files.length; i++) {
        reader.readAsDataURL(files[i]);
        reader.onload = (e) => {
            toUpload.push({
                base64: e.target.result,
                name: files[i].name,
                type: files[i].type,
                size: files[i].size
            });
        }
    };

    setUploadData({
        application_ref: props.context.auth.app_uuid,
        documents: toUpload,
        public: 1
    })
  };

  const handleLinkCopy = async (docId) => {
    const res = await DocumentVaultAPI.generateFixedLink(docId);
    try {
      await navigator.clipboard.writeText(res.data);
      setSnackMessage("Link Copied");
      setSnackOpen(true);
    } catch (e) {
      setSnackMessage(
        "Failed to Automatically Copy. Please Manually Copy this Link: " +
          res.data
      );
      setSnackOpen(true);
    }

    props.onClose();
  };

  const handleUpload = async (e) => {
    setUploadData({
      application_ref: props.context.auth.app_uuid,
      documents: [e],
      public: 1,
    });
  };

  const handleStore = async () => {
    setUploading(true);
    if (typeof uploadData.documents === "undefined") {
      setSnackMessage("Please Select a File First");
      setSnackOpen(true);
      setUploading(false);
      return;
    }
    const up = await DocumentVaultAPI.storeDocument(uploadData);
    console.log("UP: ",up);

    setSnackMessage("Upload Finished");
    setSnackOpen(true);
    setUploading(false);

    setShowUpload("none");
    setUploadData({});

    const res = await DocumentVaultAPI.documentsIndex(1);
    props.data = res.data;
  };

  const handleView = async (docId) => {
    const res = await DocumentVaultAPI.generateTempLink(docId);
    window.open(res.data, "_blank");
  };

  const columns = [
    { field: "id", headerName: "Document ID", flex: 0.1 },
    { field: "name", headerName: "File Name", flex: 0.5 },
    { field: "size", headerName: "File Size", flex: 0.1 },
    { field: "raw_type", headerName: "File Type", flex: 0.2 },
    { field: "created_by_name", headerName: "Created By", flex: 0.2 },
    { field: "created_at", headerName: "Created At", flex: 0.2 },
    {
      field: "",
      headerName: "Actions",
      minWidth: 300,
        renderCell: (cellValues) => {
          return (
            <div>
              <ModuleButton
                text="Copy Link"
                btnIcon="content_copy"
                style={{ "margin-left": "10px" }}
                onClick={() => {
                  handleLinkCopy(cellValues.row.id);
                }}
              />
              <ModuleButton
                text="View"
                btnIcon="download"
                style={{ "margin-left": "10px" }}
                onClick={() => {
                  handleView(cellValues.row.id);
                }}
              />
            </div>
          );
        },
    },
  ];

  const options = {
    page: 0,
    autoHeight: true,
    height: 680
  };

  const data = props.data;

  return (
    <div id="media-library">
      <Snackbar
        open={snackOpen}
        message={snackMessge}
        onClose={() => {
          setSnackOpen(false);
          setSnackMessage("");
        }}
        autoHideDuration={6000}
      />
      <Dialog
        fullWidth
        maxWidth="xl"
        open={props.open}
        onClose={props.onClose}
        scroll={"paper"}
        style={{ padding: "15px" }}
      >
        <DialogTitle
          style={{
            background: props.context.theme.sidebar.background,
            color: "white",
          }}
        >
          <IconButton
            onClick={() => {
              props.onClose();
            }}
            color="inherit"
          >
            <Icon>close</Icon>
          </IconButton>
          Media Library
        </DialogTitle>
        <div style={{ padding: "15px" }}>
          <div
            style={{
              display: showUpload === "none" ? "block" : "none",
              "margin-bottom": "10px",
            }}
          >
            <ModuleButton
              text="Upload File"
              btnIcon="upload"
              onClick={() => {
                setShowUpload("block");
              }}
            />
          </div>
          <div id="uploadForm" style={{ display: showUpload }}>
            <div className="form-row">
              <ModuleButton
                text="Cancel Upload"
                btnIcon="cancel"
                onClick={() => {
                  setShowUpload("none");
                }}
              />
            </div>
            <hr />
            <div className="form-row">
              <p
                style={{
                  color: "red",
                  "font-weight": "bold",
                  "padding-left": "15px",
                }}
              >
                WARNING: All Files Uploaded to the Media Library Are Publicly
                Available. Do Not Upload Anything You Do Not Wish To Be Accessed
                By Everyone.
              </p>
            </div>
            <div className="form-row">
              <div className="form-group col-lg-4">
                {/* <FileBase64 multiple={false} onDone={(e) => handleUpload(e)} /> */}
                <Button
                  variant="contained"
                  component="label"
                  onChange={(evt) => handleInputChange(evt)}
                >
                  Select File
                  <input
                    type="file"
                    hidden={true}
                  />
                </Button>
                <i>Max Size: 5MB</i>
              </div>
              <div className="form-group col-lg-4">
                <Button
                  disabled={uploading}
                  variant="contained"
                  onClick={() => {
                    handleStore();
                  }}
                  style={{
                    background: props.context.theme.sidebar.background,
                    color: "white",
                  }}
                >
                  {uploading ? (
                    <CircularProgress color="success" size={24} />
                  ) : (
                    <Icon>upload</Icon>
                  )}{" "}
                  Upload
                </Button>
              </div>
            </div>
            <hr />
          </div>
          <DataTable
            columns={columns}
            data={data}
            pagination={false}
            options={options}
            allowDelete={false}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </div>
      </Dialog>
    </div>
  );
}
