import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../common/DataTable";
import { DocCreatorApi, ApiLoaderComponent, LoadingComponent } from "@unity/components";
import { CanDelete } from "../services/Permissions";
import ModuleButton from "../common/ModuleButton";
import InputCapture from "../common/InputCapture";
import WarningCheck from "../common/WarningCheck";
import ModuleFunctions from "../common/ModuleFunctions";

export default function ActionIndex({ context, create, read, edit, admin, readAll }) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState(false);
  const [warning, setWarning] = useState(false);
  const [warningData, setWarningData] = useState(false);
  const [replaceLog, setReplaceLog] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  const [types, setTypes] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState(true);// Flag to switch between unpaginated and paginated data!
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [searchText, setSearchText] = useState('');
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!

  const history = useHistory();

  const dialogChange = (data) => {
    setOpen(true);
    setDialogData(prevState => ({
      ...prevState,
      ...data
    }));
  };

  const checkWarning = (data) => {
    let main = Math.floor(data.current);
    let text = `You are about to publish version ${data.current} of this document! It will be published as version ${main + 1}.0.`;

    if(main > 0) {
      text = text + ` This will replace version ${main}.0 of this document!`;
      setReplaceLog({
        log: `Document has been published and replaces version ${main}.0`
      });
    }
    text = text + ' Are you sure you want to continue?'

    setWarning(true);
    setWarningData({
      id: data.id,
      info: text
    });
  };

  const typesChange = (data) => {
    setTypes(data);
  };

  const getDocs = async () => {
    const res = await DocCreatorApi.getActionDocuments({ read_all: readAll, pagination: pagination, page: page + 1, per_page: pageSize, search_term: searchText });

    if (res.success) {
      setData(res.data);
      if (pagination) {
        setRowCount(res.meta.total);
      }
    } else {
      // the request has failed, load an empty array!
      setData([]);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);

    if ( pagination ) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if( pagination ) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleLinkClick = (event, params, path) => {
      event.preventDefault();
      history.push({
        pathname: `/doc-creator/${path}/${params.id}`,
        state: { ...params }
      });
  };

  // The following functions are both required for the correct
  // async use of deleting multiple rows!
  // -----------------------------------------------------------------------------------
  const handleDelete = async (id) => {
    await DocCreatorApi.deleteDocument(id);
  };

  const handleDeleteArray = async (array) => {
    setLoading({ status: true, data: "Deleting documents, please wait..." });
    const unresolvedPromises = array.map(id => handleDelete(id));

    await Promise.all(unresolvedPromises).then(success => {
      setLoading({ status: true, data: "Succssfully deleted documents!" });
    }).catch(error => {
      setLoading({ status: true, data: `Unable to delete document with id: ${error}` });
    });
    
    setFetchTrigger(fetchTrigger + 1);
    
    setTimeout(() => {
      setLoading({ status: false });
    }, 3000);
  };
  // -----------------------------------------------------------------------------------

  const handleApproval = async (id) => {
    setLoading({ status: true, data: 'Approving document, please wait...' });
    const res = await DocCreatorApi.documentApproval(id, {});
    if(res.success) {
      await ModuleFunctions.handleLogSave({
        type_id: res.data.type_id,
        types: types,
        log: "Document has been checked and approved.",
        document: res.data, event: "approved"
      });

      setLoading({ status: false });
    } else {
      setLoading({ status: true, data: res.message });
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
    }

    setFetchTrigger(fetchTrigger + 1);
  };

  const handlePublish = async (id) => {
    setLoading({ status: true, data: 'Publishing document, please wait...' });
    setWarning(false);
    setWarningData(false);

    const res = await DocCreatorApi.documentPublishing(id, {});

    if (res.success) {
      await ModuleFunctions.handleLogSave({
        type_id: res.data.type_id,
        types: types,
        log: replaceLog ? replaceLog.log : 'Document has been published',
        document: res.data,
        event: "published"
      });

      setLoading({ status: false });
      setReplaceLog(false);
    } else {
      setLoading({ status: true, data: res.message });
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
    }

    setFetchTrigger(fetchTrigger + 1);
  };

  const handleRejectRequest = async () => {
    setLoading({ status: true, data: 'Cancelling approval request for document, please wait...' });

    const testResults = ModuleFunctions.testReason({ reason: dialogData.reason });

    if(testResults) {
      setErrors(testResults);
      setLoading({ status: false });
    } else {
      const res = await DocCreatorApi.documentRejection(dialogData.id, { reason: dialogData.reason });

      if (res.errors) {
        setLoading({ status: true, data: 'Unable to cancel approval request for document!' });
        setTimeout(setLoading({ status: false }), 3000);
      } else {
        setLoading({ status: false });
        setOpen(false);
        setDialogData(false);
        setErrors(false);
      }
  
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const columns = [
    { field: "reference", headerName: "Ref#", flex: 0.3 },
    { field: "title", headerName: "Document Name", flex: 1 },
    { field: "version", headerName: "Version", flex: 0.2 },
    { field: "bu_name", headerName: "Business Unit", flex: 0.5 },
    {
      field: "",
      headerName: "Action",
      minWidth: 400,
      renderCell: (cellValues) => {
        return (
          <div>
            <ModuleButton
              btnIcon="visibility"
              style={{
                background: context.theme.sidebar.background,
                color: "white"
              }}
              onClick={(event) => handleLinkClick(event, cellValues.row, "view")}
              tip="View"
            />
            <ModuleButton
              btnIcon="chrome_reader_mode"
              style={{
                background: context.theme.sidebar.background,
                color: "white",
                marginLeft: 10
              }}
              onClick={(event) => handleLinkClick(event, cellValues.row, "history")}
              tip="History"
            />
            {admin && !cellValues.row.published ? (
              cellValues.row.approved ? (
                <ModuleButton
                  btnIcon="cloud_upload"
                  style={{
                    background: "orange",
                    color: "white",
                    marginLeft: 10
                  }}
                  tip="Publish"
                  onClick={() => checkWarning({ id: cellValues.row.id, theme: cellValues.row.theme_id, current: cellValues.row.version })}
                />
              ) : (
                <ModuleButton
                  btnIcon="card_membership"
                  style={{
                    background: "green",
                    color: "white",
                    marginLeft: 10
                  }}
                  tip="Approve"
                  onClick={() => handleApproval(cellValues.row.id)}
                />
              )
            ) : (
              <>
              </>
            )}
            {admin &&
              <ModuleButton
                btnIcon="backspace"
                style={{
                  background: "red",
                  color: "white",
                  marginLeft: 10
                }}
                tip="Reject Request"
                onClick={() => dialogChange({ id: cellValues.row.id })}
              />
            }
            
          </div>
        );
      },
    }
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 680
  };
  
  useEffect(() => {
    ModuleFunctions.getTypes({ change: typesChange });
    getDocs();
  }, [fetchTrigger]);

  if (read && data) {
    return (
      <>
        <DataTable
          columns={columns}
          data={data}
          pagination={pagination}
          options={options}
          allowDelete={CanDelete("doc-creator")}
          handleDeleteArray={handleDeleteArray}
          searchText={searchText}
          setSearchText={setSearchText}
          fetchTrigger={fetchTrigger}
          setFetchTrigger={setFetchTrigger}
        />

        <InputCapture
          title="Rejection Reason"
          info="You must supply a reason to inform the requester why the approval request has been rejected"
          open={open}
          setOpen={setOpen}
          errors={errors}
          setErrors={setErrors}
          dialogData={dialogData}
          setDialog={setDialogData}
          dialogChange={dialogChange}
          handleSubmit={handleRejectRequest}
        />

        <WarningCheck
          open={warning}
          setOpen={setWarning}
          dialogData={warningData}
          setDialogData={setWarningData}
          handleSubmit={handlePublish}
        />

        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
