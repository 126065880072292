import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ModuleButton from "../common/ModuleButton";

export default function WarningCheck(props) {
    const handleCancel = () => {
      props.setOpen(false);
      props.setDialogData(false);
    };

    return (
        <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>Warning!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.dialogData.info}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModuleButton
            btnIcon="check"
            text="continue"
            style={{
              background: "green",
              color: "white",
              marginRight: 10
            }}
            tip="Continue"
            onClick={() => props.handleSubmit(props.dialogData.id)}
          />
          <ModuleButton
            btnIcon="close"
            text="cancel"
            style={{
              background: "red",
              color: "white",
              marginRight: 10
            }}
            tip="Cancel"
            onClick={() => handleCancel()}
          />
        </DialogActions>
      </Dialog>
    );
}
