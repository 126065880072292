import React, { useState } from "react";
import { FormControl, FormHelperText, TextField, InputLabel, MenuItem, Select } from "@mui/material";
import ModuleFunctions from "../common/ModuleFunctions";

export default function DocSetupForm(props) {

  return (
    <div className="block">
      <div className="container-fluid">

        <div className="form-row">
          <div className="form-group col-lg-12">
            <h3>Document Setup</h3>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-10">
            <TextField
              label="Title"
              name="title"
              type="text"
              value={props.document.title || ""}
              error={props.errors && props.errors.title || false}
              helperText={props.errors && props.errors.title || ""}
              onChange={(e) => ModuleFunctions.handleDocChange({ name: e.target.name, value: e.target.value, docChange: props.docChange })}
              fullWidth={true}
            ></TextField>
          </div>
          <div className="form-group col-lg-2">
            <FormControl error={props.errors && props.errors.type_id || false} fullWidth>
            <InputLabel>Document Type</InputLabel>
            <Select
              label="Document Type"
              name="type_id"
              value={props.document.type_id || ""}
              onChange={(e) => ModuleFunctions.handleDocChange({ name: e.target.name, value: e.target.value, docChange: props.docChange })}
            >
              {props.types && props.types.map((item, key) => {
                  return <MenuItem key={key} value={item.id}>{item.value}</MenuItem>
              })}
            </Select>
            <FormHelperText>{props.errors && props.errors.type_id || ""}</FormHelperText>
            </FormControl>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-6">
            <FormControl fullWidth>
            <InputLabel>To assign the document to an SBU, select here</InputLabel>
            <Select
              label="To assign the document to an SBU, select here"
              name="sbu_id"
              value={props.document.sbu_id || ""}
              onChange={(evt, menuItem) => ModuleFunctions.handleAssign({ event: evt, element: menuItem, docChange: props.docChange, catsChange: props.catsChange })}
            >
              {props.bus && props.bus.map((item, key) => {
                  return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
              })}
            </Select>
            </FormControl>
          </div>
          <div className="form-group col-lg-6">
            <FormControl fullWidth>
            <InputLabel>Then to assign to a Product Category, select here</InputLabel>
            <Select
              label="Then to assign to a Product Category, select here"
              name="prod_cat_id"
              value={props.document.prod_cat_id || ""}
              onChange={(evt, menuItem) => ModuleFunctions.handleAssign({ event: evt, element: menuItem, docChange: props.docChange, catsChange: props.catsChange })}
            >
              {props.cats && props.cats.map((item, key) => {
                  return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
              })}
            </Select>
            </FormControl>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-12">
            <TextField
              label="Briefly describe the purpose of this document for the version control logs"
              name="description"
              value={props.log.description || ""}
              multiline
              minRows={6}
              maxRows={12}
              fullWidth={true}
              error={props.errors && props.errors.description || false}
              helperText={props.errors && props.errors.description || ""}
              onChange={(e) =>
                ModuleFunctions.handleLogChange({ name: e.target.name, value: e.target.value, logChange: props.logChange })
              }
            ></TextField>
          </div>
        </div>

        <div className="form-row">
        <div className="form-group col-lg-12">
          <FormControl fullWidth>
          <InputLabel>If you would like to copy an existing document to start from, select above</InputLabel>
          <Select
            label="If you would like to copy an existing document to start from, select above"
            name="copied"
            value={props.document.copied || ""} 
            onChange={(evt, menuItem) => ModuleFunctions.handleCopy({ event: evt, element: menuItem, docChange: props.docChange })}
          >
            {props.published && props.published.map((item, key) => {
                // item.menu is passed through to get the menu item if they want to copy a document!
                return <MenuItem key={key} value={item.id} menu={item.menu}>{item.title}</MenuItem>
            })}
          </Select>
          </FormControl>
          </div>
        </div>
        
      </div>
    </div>
  );
}
